.calendar-event {
    padding-top: 1.1em;
    padding-bottom: 1.1em;
}

.calendar-event-title {
    font-family: Helvetica, Arial, sans-serif;
    font-size:1.4em;

}

.location {
    font-family: 'Courier New', Courier, monospace;
    font-size:1.0em;
}

.time-span {
    font-family: 'Courier New', Courier, monospace;
    font-size:1.2em;
}

.calendar-event-explicit{
    margin-top: 0.2em;
}

.event-highlighted{
    animation: highlight-colour-change 3s infinite;
  }

@keyframes highlight-colour-change {
    0% { color: #330000; }
    50% { color: black; }
    100% { color: #330000; }
}